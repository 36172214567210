var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var toolTip = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, on, toolTip)),[_vm._v(" help_outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t ('general.label.clickInfo')))])])]}}]),model:{value:(_vm.isMenuActive),callback:function ($$v) {_vm.isMenuActive=$$v},expression:"isMenuActive"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5",domProps:{"textContent":_vm._s(_vm.$t ('certstores.validityDialog.headling', {name: _vm.name}))}}),_c('v-card-text',[_c('v-text-field',{attrs:{"clearable":"","label":_vm.$t ('general.label.search')},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.validity,"search":_vm.searchTerm,"headers":_vm.headers,"loading":_vm.isLoading,"no-results-text":_vm.noResultsText (_vm.isLoading, _vm.$t ('certstores.validityDialog.empty')),"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.alias))]),_c('td',[_vm._v(_vm._s(item.subjectDistinguishedName))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var iconToolTip = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":item.icon.color}},iconToolTip),[_vm._v(" "+_vm._s(item.icon.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t (("certstores.validityDialog.validityToolTip." + (item.validity)))))])])],1),_c('td',[_vm._v(_vm._s(_vm.formatDate (item.from)))]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate (item.to))+" ")])])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.close'),expression:"'general.button.close'"}],attrs:{"text":"","disabled":_vm.isLoading},on:{"click":function($event){_vm.isMenuActive = false}}}),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.refresh'),expression:"'general.button.refresh'"}],attrs:{"disabled":_vm.isLoading,"color":"primary"},on:{"click":_vm.loadValidityMap}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }