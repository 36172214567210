<messages>["../Configuration"]</messages>

<!--
================================================================================
  Template
================================================================================
-->

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t (!!name ? `certstores.dialog.${type}.update` :
      `certstores.dialog.${type}.add`)"
    :is-loading="isLoading"
    :error-msg="errorMessage"
    @ok="onOk">
    <template #activator="{ on }">
      <slot
        name="activator" :on="on"/>
    </template>

    <form novalidate>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model.trim="storeData.name"
              class="required"
              :label="$t ('certstores.dialog.name')"
              :readonly="!!name"
              spellcheck="false"
              :error-messages="requiredErrors (
                'storeData.name',
                'certstores.dialog.required.name')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <file-upload
              v-model="storeFileName"
              required
              :label="$t ('certstores.dialog.file')"
              :error-messages="validationErrors (
                'storeData.data',
                {
                  required: 'certstores.dialog.required.data',
                  validFile: 'certstores.dialog.invalid.data'
                })"
              @load="onLoad"
              @error="onError"/>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="storeData.type"
              class="required"
              :label="$t ('certstores.dialog.type')"
              :items="availableTypes"
              :error-messages="requiredErrors (
                'storeData.type',
                'certstores.dialog.required.type')"/>
          </v-col>
          <v-col cols="6">
            <!--
              these fields are a workaround to prevent autocompletion
              of the field (GWNG-863/GWNG-416)
            -->
            <input id="email" style="display:none" type="text" name="ffWorkaroundEmail">
            <input id="password" style="display:none" type="password" name="ffWorkaroundPassoword">
            <v-text-field
              v-model="storeData.storePassword"
              autocomplete="new-password"
              class="required"
              :label="$t ('certstores.dialog.storePw')"
              spellcheck="false"
              :append-icon="isStorePwHidden ? 'visibility' : 'visibility_off'"
              :type="isStorePwHidden ? 'password' : 'text'"
              :error-messages="requiredErrors (
                'storeData.storePassword',
                'certstores.dialog.required.storePw')"
              @click:append="isStorePwHidden = !isStorePwHidden"/>
          </v-col>
          <v-col
            v-if="type === 'key'"
            cols="6">
            <!--
              these fields are a workaround to prevent autocompletion
              of the field (GWNG-863/GWNG-416)
            -->
            <input id="email" style="display:none" type="text" name="ffWorkaroundEmail">
            <input id="password" style="display:none" type="password" name="ffWorkaroundPassoword">
            <v-text-field
              v-model="storeData.keyPassword"
              class="required"
              :label="$t ('certstores.dialog.keyPw')"
              spellcheck="false"
              :append-icon="isKeyPwHidden ? 'visibility' : 'visibility_off'"
              :type="isKeyPwHidden ? 'password' : 'text'"
              :error-messages="requiredErrors (
                'storeData.keyPassword',
                'certstores.dialog.required.keyPw')"
              @click:append="isKeyPwHidden = !isKeyPwHidden"/>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </confirmation-dialog>
</template>

<!--
================================================================================
  Logic
================================================================================
-->

<script>
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import FileUpload from '@/app/core/components/FileUpload/FileUpload'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import {required, requiredIf} from 'vuelidate/lib/validators'
  import {mapActions} from 'vuex'

  export default {
    name: 'CertificateStoreDialog',

    components: {
      ConfirmationDialog,
      FileUpload
    },

    mixins: [validationMixins],

    props: {
      value: {
        // whether the dialog is shown
        type: Boolean,
        required: true
      },
      type: {
        // type of the certificate store
        type: String,
        required: true,
        validator (v) {
          return ['key', 'trust'].includes (v)
        }
      },
      name: {
        // key store name (for updates)
        type: String,
        default: undefined
      }
    },

    data () {
      return {
        storeData: {
          name: '',
          type: '',
          storePassword: '',
          keyPassword: '',
          data: ''
        },
        storeFileName: '',
        isStoreFileValid: true,
        isStorePwHidden: true,
        isKeyPwHidden: true,
        availableTypes: [
          {text: 'JKS', value: 'jks'},
          {text: 'PKCS#12', value: 'pkcs12'}
        ],
        isLoading: false,
        showErrorMessage: false
      }
    },

    computed: {
      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      errorMessage () {
        return this.showErrorMessage ? this.$t ('certstores.dialog.error') : ''
      }
    },

    watch: {
      showDialog (newValue) {
        if (newValue) this.init ()
      }
    },

    validations () {
      return {
        storeData: {
          name: {required},
          type: {required},
          storePassword: {required},
          keyPassword: {
            required: requiredIf (function () {
              return this.type === 'key'
            })
          },
          data: {required, validFile: () => this.isStoreFileValid}
        }
      }
    },

    methods: {
      ...mapActions ('request', ['fetchData']),

      /**
       * Initialize this dialog by preparing the internal store data structure
       * and resetting error messages and validations.
       */
      init () {
        this.storeData.name = this.name || ''
        this.storeData.type = ''
        this.storeData.storePassword = ''
        this.storeData.keyPassword = ''
        this.storeData.data = ''

        this.storeFileName = ''
        this.isStoreFileValid = true
        this.showErrorMessage = false

        this.$v.$reset ()
      },

      /**
       * Send an updateKeyStore or updateTrustStore request when the OK button
       * is clicked and the form data is valid.
       */
      onOk () {
        this.$v.$touch ()

        if (!this.$v.$error) {
          this.isLoading = true

          let op

          const params = {
            name: this.storeData.name,
            type: this.storeData.type,
            storePassword: this.storeData.storePassword,
            data: this.storeData.data
          }

          switch (this.type) {
            case 'key':
              op = 'registry/config/updateKeyStore'
              params.keyPassword = this.storeData.keyPassword
              break

            case 'trust':
              op = 'registry/config/updateTrustStore'
              break
          }

          this.fetchData ({
            op,
            params,
            cb: () => {
              this.showDialog = false
              this.$emit ('success', this.storeData.name)
            },
            cbError: () => {
              this.showErrorMessage = true
              this.$emit ('failure')
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        }
      },

      /**
       * Set the internal representation of store type and store data when the
       * store file has been loaded successfully.
       */
      onLoad (e) {
        this.storeData.data = e.data
        this.isStoreFileValid = true

        switch (e.type) {
          case 'application/x-java-keystore':
            this.storeData.type = 'jks'
            break
          case 'application/x-pkcs12':
            this.storeData.type = 'pkcs12'
            break
          default:
            console.warn ('unexpected store MIME type "' + e.type + '"')
            this.storeData.type = ''
        }
      },

      /**
       * Make the validation fail when the store file could not be loaded
       * successfully.
       */
      onError () {
        this.isStoreFileValid = false
      }
    }
  }
</script>
