<messages>["../Configuration"]</messages>

<!--
================================================================================
  Template
================================================================================
-->

<template>
  <v-card :flat="flat">
    <v-card-title primary-title>
      <div
        v-t="`certstores.${type}.title`"
        class="text-h5"/>
    </v-card-title>
    <v-card-text>
      <v-data-table
        hide-default-footer
        class="elevation-1"
        :custom-sort="sortFunction"
        :headers="headers"
        :items="stores"
        :items-per-page="-1"
        :no-data-text="$t (`certstores.${type}.empty`)">
        <template #item="props">
          <tr :key="props.item.id">
            <td>{{ props.item.name }}</td>
            <td>{{ formatDate (props.item.validTo) }}</td>
            <td>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    v-if="props.item.validity === 'valid'"
                    color="green"
                    v-on="on">
                    check_circle
                  </v-icon>
                  <v-icon
                    v-if="props.item.validity === 'expiring'"
                    color="orange"
                    v-on="on">
                    warning
                  </v-icon>
                  <v-icon
                    v-if="props.item.validity === 'expired'"
                    color="red"
                    v-on="on">
                    error
                  </v-icon>
                  <v-icon
                    v-if="props.item.validity === 'not-yet-valid'"
                    color="red"
                    v-on="on">
                    update
                  </v-icon>
                </template>
                <span v-t="`stores.valdesc.${props.item.validity}`"/>
              </v-tooltip>
              <validity-menu
                v-if="type === 'trust'"
                :id="props.item.id"
                :name="props.item.name"/>
            </td>
            <td v-if="showActions">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    v-if="props.item.inUse"
                    v-on="on">
                    check
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey lighten-2"
                    v-on="on">
                    remove
                  </v-icon>
                </template>
                <span v-t="`stores.usagedesc.${props.item.inUse}`"/>
              </v-tooltip>
            </td>
            <td v-if="showActions">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    class="ma-0 pa-0"
                    v-on="on"
                    @click="openDialog (props.item.name, 'update')">
                    <v-icon>update</v-icon>
                  </v-btn>
                </template>
                <span v-t="'stores.update'"/>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    class="ma-0 pa-0"
                    :disabled="props.item.inUse"
                    v-on="on"
                    @click="openDialog (props.item.name, 'delete')">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    $t (props.item.inUse ? 'stores.noDelete' : 'stores.delete')
                  }}
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    class="ma-0 pa-0"
                    v-on="on"
                    @click="setDlDialog (props.item)">
                    <v-icon>get_app</v-icon>
                  </v-btn>
                </template>
                <span v-t="'general.button.download'"/>
              </v-tooltip>

              <v-tooltip v-if="type === 'trust'" bottom>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    class="ma-0 pa-0"
                    v-on="on"
                    @click="setUpDialog (props.item)">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span v-t="'certstores.uploadToolTip'"/>
              </v-tooltip>

              <v-tooltip v-if="type === 'key'" bottom>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    class="ma-0 pa-0"
                    v-on="on"
                    @click="setUpdateDialog (props.item)">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span v-t="'certstores.updateCert.update'"/>
              </v-tooltip>

              <v-tooltip v-if="type === 'key'" bottom>
                <template #activator="{ on }">
                  <a
                    :href="getCSRLink (props.item.id)" v-on="on">
                    <v-btn
                      icon
                      class="ma-0 pa-0">
                      <v-icon>gesture</v-icon>
                    </v-btn>
                  </a>
                </template>
                <span v-t="'certstores.createCSR'"/>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <certificate-store-dialog
      v-model="showUpdateDialog"
      :type="type"
      :name="name"
      @success="$emit ('update', name)"/>

    <confirmation-dialog
      v-model="showDeleteDialog"
      :headline="$t (`certstores.dialog.${type}.delete`)"
      :is-loading="isLoadingDelete"
      @ok="onDelete (name)">
      <span>{{ deleteDialogText }}</span>
    </confirmation-dialog>

    <store-download-dialog
      v-model="dlDialog"
      :item="dlItem"
      @ok="downloadCurrent"
      @cancel="cancel"/>

    <add-cert-dialog
      v-model="upDialog"
      :item="uploadItem"
      :is-loading="loadingUpload"
      @ok="uploadCurrent"
      @cancel="cancelUpload"/>
    <update-cert-dialog
      v-model="updateDialog"
      :item="updateItem"
      :is-loading="loadingUpdate"
      @ok="updateCert"/>
  </v-card>
</template>

<!--
================================================================================
  Logic
================================================================================
-->

<script>
  import CertificateStoreDialog from './CertificateStoreDialog'
  import StoreDownloadDialog from './StoreDownloadDialog'
  import AddCertDialog from './AddCertDialog'
  import UpdateCertDialog from './UpdateCertDialog'
  import ValidityMenu from './ValidityMenu'
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  import {mapActions, mapGetters, mapMutations} from 'vuex'

  export default {
    name: 'CertificateStores',

    components: {
      CertificateStoreDialog,
      ConfirmationDialog,
      StoreDownloadDialog,
      AddCertDialog,
      UpdateCertDialog,
      ValidityMenu
    },

    props: {
      type: {
        type: String,
        required: true,
        validator (v) {
          return ['key', 'trust'].includes (v)
        }
      },
      stores: {
        type: Array,
        default () {
          return []
        }
      },
      showActions: {
        type: Boolean,
        default: true
      },
      flat: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        showUpdateDialog: false,
        showDeleteDialog: false,
        name: '',
        isLoadingDelete: false,
        dlItem: null,
        dlDialog: false,
        upDialog: false,
        uploadItem: null,
        updateDialog: false,
        updateItem: null,
        loadingUpload: false,
        loadingUpdate: false
      }
    },

    computed: {
      headers () {
        const baseColumns = [
          {
            text: this.$t ('stores.name'),
            value: 'name'
          },
          {
            text: this.$t ('stores.validTo'),
            value: 'validTo'
          },
          {
            text: this.$t ('stores.validity'),
            value: 'validity'
          }
        ]

        if (this.showActions) {
          return baseColumns.concat ([
            {
              text: this.$t ('stores.usage'),
              value: 'inUse'
            },
            {
              text: this.$t ('stores.actions'),
              value: 'actions',
              sortable: false
            }
          ])
        } else {
          return baseColumns
        }
      },

      deleteDialogText () {
        return this.$t (`certstores.dialog.${this.type}.deleteText`,
                        {store: this.name})
      },

      ...mapGetters ({
        operationLink: 'request/operationLink'
      })
    },

    methods: {
      ...mapMutations ({
        displayErrorMessage: 'notification/setError',
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ('request', ['fetchData']),

      /**
       * Show the dialog of the given type.
       *
       * @param {String} name     the store name to open the dialog for
       * @param {String} type     the type of the dialog, one of 'update',
       *                          'delete'
       */
      openDialog (name, type) {
        this.name = name

        switch (type) {
          case 'update':
            this.showUpdateDialog = true
            break

          case 'delete':
            this.showDeleteDialog = true
            break
          default:
            console.error ('unexpected dialog type "' + type + '"')
        }
      },

      setDlDialog (item) {
        this.dlItem = item
        this.dlDialog = true
      },

      downloadCurrent (pw) {
        this.download (this.downloadLink (this.dlItem.id, pw))
        this.dlDialog = false
        this.dlItem = null
      },

      cancel () {
        this.dlItem = null
        this.dlDialog = false
      },

      uploadCurrent (e) {
        this.loadingUpload = true
        this.fetchData ({
          op: 'registry/config/addToTrustStore',
          params: {
            data: e.cert,
            id: this.uploadItem.id,
            string: e.alias
          },
          cb: () => {
            this.uploadItem = null
            this.upDialog = false
            this.displaySuccessMessage (this.$t ('certstores.addSuccess'))
            this.$emit ('update', this.name)
          },
          cbError: (data, next) => {
            if (data.result[0].code === 'error/datamgmt/value') {
              this.displayErrorMessage (this.$t ('certstores.addError'))
            } else {
              next (data)
            }
          },
          cbFinal: () => {
            this.loadingUpload = false
          }
        })
      },

      updateCert ({chain, errorCb}) {
        this.loadingUpdate = true

        this.fetchData ({
          op: 'registry/config/addToKeyStore',
          params: {
            data: chain,
            id: this.updateItem.id
          },
          cb: () => {
            this.updateItem = null
            this.updateDialog = false
            this.displaySuccessMessage (this.$t ('certstores.updateCert.success'))
            this.$emit ('update', this.name)
          },
          cbError: data => {
            this.displayErrorMessage (this.$t ('certstores.updateCert.error'))
            if (errorCb) errorCb (data)
          },
          cbFinal: () => {
            this.loadingUpdate = false
          }
        })
      },

      cancelUpload () {
        this.uploadItem = null
        this.upDialog = false
      },

      setUpDialog (item) {
        this.uploadItem = item
        this.upDialog = true
      },

      setUpdateDialog (item) {
        this.updateItem = item
        this.updateDialog = true
      },

      download (url) {
        const a = document.createElement ('a')
        a.style.display = 'none'
        document.body.appendChild (a)
        a.href = url
        a.click ()
        window.URL.revokeObjectURL (a.href)
        document.body.removeChild (a)
      },

      /**
       * Get the download link for the keystore with the given id
       *
       * @param {Number} id       the id of the keystore
       * @param {String} pw       the password for the keystore
       */
      downloadLink (id, pw) {
        return this.operationLink ({
          op: `registry/config/load-${this.type}store`,
          params: {
            id,
            string: pw
          }
        })
      },

      getCSRLink (id) {
        return this.operationLink ({
          op: 'registry/config/create-csr',
          params: {
            id
          }
        })
      },

      /**
       * Delete a certificate store.
       *
       * @param {String} name     the name of the store to delete
       */
      onDelete (name) {
        let op
        this.isLoadingDelete = true

        switch (this.type) {
          case 'key':
            op = 'registry/config/deleteKeyStore'
            break

          case 'trust':
            op = 'registry/config/deleteTrustStore'
            break
        }

        this.fetchData ({
          op,
          params: {
            name
          },
          cb: () => {
            this.$emit ('delete', name)
          },
          cbError: () => {
            this.$emit ('deleteError', name)
          },
          cbFinal: () => {
            this.showDeleteDialog = false
            this.isLoadingDelete = false
          }
        })
      },

      /**
       * Sort the given array of table items by the specified column.
       *
       * @param {Array} items                     the items to sort
       * @param {Array<String>} columns           the names of the columns to
       *                                          sort by
       * @param {Array<Boolean>} descendingArr    will sort descendingly if
       *                                          true, ascendingly if false
       *                                          and not at all if null
       */
      sortFunction (items, columns, descendingArr) {
        if (descendingArr.length < 1) return items
        const descending = descendingArr[0]
        if (descending === null) return items
        if (columns.length < 1) return items
        const first = columns[0]

        switch (first) {
          case 'name':
            items.sort ((i1, i2) => descending
              ? i2.name.localeCompare (i1.name)
              : i1.name.localeCompare (i2.name))
            break

          case 'validTo':
            items.sort ((i1, i2) => descending
              ? i2.validTo - i1.validTo
              : i1.validTo - i2.validTo)
            break

          case 'validity':
            items.sort ((i1, i2) => descending
              ? this.compareValidity (i1.validity, i2.validity)
              : -this.compareValidity (i1.validity, i2.validity))
            break

          case 'inUse':
            items.sort ((i1, i2) =>
              i1.inUse === i2.inUse
                ? 0
                : descending
                  ? i2.inUse ? -1 : 1
                  : i1.inUse ? -1 : 1)
            break

          case 'actions':
            console.warn ('sorting by action is not supported')
            break
        }

        return items
      },

      /**
       * Compare the given validity values.
       * The validity order (ascending) is 'valid', 'not-yet-valid',
       * 'expiring', 'expired'.
       *
       * @param {String} v1     the first value to compare
       * @param {String} v2     the second value to compare
       * @return {Number}       0 if v1 and v2 are equal,
       *                        -1 if v1 is lower than v2,
       *                        1 if v1 is greater than v2
       */
      compareValidity (v1, v2) {
        if (v1 === v2) return 0

        switch (v1) {
          case 'valid':
            return 1

          case 'expired':
            return -1

          case 'not-yet-valid':
            return v2 === 'valid' ? -1 : 1

          case 'expiring':
            return v2 === 'expired' ? 1 : -1
        }
      }
    }
  }
</script>
