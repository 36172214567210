<messages>["../Configuration"]</messages>

<template>
  <confirmation-dialog
    v-model="dialog"
    :headline="$t (`certstores.download`, {name: item ? item.name : 'n/a'})"
    @cancel="onCancel"
    @ok="onOk">
    <form>
      {{ $t('certstores.downloadSubtitle') }}
      <v-text-field
        v-model="password"
        :label="$t('certstores.password')"
        :error-messages="validationErrors (
          'password', {
            required: 'general.required'
          }
        )"
        @keyup.enter="onOk"/>
    </form>
  </confirmation-dialog>
</template>

<script>
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import {required} from 'vuelidate/lib/validators'

  export default {
    components: {
      ConfirmationDialog
    },

    mixins: [validationMixins],

    validations: {
      password: {required}
    },

    props: {
      value: {
        type: Boolean,
        required: true
      },
      item: {
        type: Object,
        default: undefined
      }
    },

    data () {
      return {
        password: ''
      }
    },

    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      }
    },

    methods: {
      onOk () {
        if (!this.$v.$invalid) {
          this.$emit ('ok', this.password)
          this.password = ''
        } else {
          this.$v.$touch ()
        }
      },
      onCancel () {
        this.$emit ('cancel')
        this.password = ''
        this.$v.$rest ()
      }
    }
  }
</script>
