<messages>["../Configuration"]</messages>

<template>
  <base-dialog v-model="isMenuActive">
    <template #activator="{on}">
      <v-tooltip bottom>
        <template #activator="{on: toolTip}">
          <v-icon v-on="{...on, ...toolTip}">
            help_outline
          </v-icon>
        </template>
        <span>{{ $t ('general.label.clickInfo') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title
        class="text-h5"
        v-text="$t ('certstores.validityDialog.headling', {name})"/>

      <v-card-text>
        <v-text-field
          v-model="searchTerm"
          clearable
          :label="$t ('general.label.search')"/>
        <v-data-table
          :items="validity"
          :search="searchTerm"
          :headers="headers"
          :loading="isLoading"
          :no-results-text="noResultsText (isLoading, $t ('certstores.validityDialog.empty'))"
          :footer-props="footerProps"
          class="elevation-1">
          <template #item="{item}">
            <tr>
              <td>{{ item.alias }}</td>
              <td>{{ item.subjectDistinguishedName }}</td>
              <td>
                <v-tooltip bottom>
                  <template #activator="{ on: iconToolTip }">
                    <v-icon :color="item.icon.color" v-on="iconToolTip">
                      {{ item.icon.name }}
                    </v-icon>
                  </template>
                  <span>{{ $t (`certstores.validityDialog.validityToolTip.${item.validity}`) }}</span>
                </v-tooltip>
              </td>
              <td>{{ formatDate (item.from) }}</td>
              <td> {{ formatDate (item.to) }} </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.close'"
          text
          :disabled="isLoading"
          @click="isMenuActive = false"/>
        <v-btn
          v-t="'general.button.refresh'"
          :disabled="isLoading"
          color="primary"
          @click="loadValidityMap"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  import {footerProps, noResultsText} from '@/app/core/mixins/PaginationComponent'

  export default {
    name: 'ValidityMenu',

    components: {
      BaseDialog
    },

    props: {
      id: {
        type: Number,
        required: true
      },

      name: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        isMenuActive: false,
        isLoading: false,
        validityMap: null,
        searchTerm: ''
      }
    },

    computed: {
      // default footer properties, which can be used for `VDataTable` component
      footerProps,

      headline () {
        return this.name
      },

      headers () {
        return ['alias', 'subjectDistinguishedName', 'validity', 'from', 'to']
          .map ((n) => ({text: this.$t (`certstores.validityDialog.${n}`), value: n}))
      },

      validity () {
        if (this.validityMap) {
          const icon = (v) => ({name: v ? 'check_circle' : 'error', color: v ? 'success' : 'error'})
          return Object.keys (this.validityMap).map ((key) => ({
            alias: key,
            validity: this.validityMap[key].valid,
            subjectDistinguishedName: this.validityMap[key].subjectDistinguishedName,
            from: this.validityMap[key].validFrom,
            to: this.validityMap[key].validTo,
            icon: icon (this.validityMap[key].valid)
          }))
        } else {
          return []
        }
      }
    },

    watch: {
      isMenuActive (newValue) {
        if (!this.validityMap && !this.isLoading && newValue) {
          this.loadValidityMap ()
        }
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      // default text properties, which can be used for `VDataTable` component
      noResultsText,

      loadValidityMap () {
        this.isLoading = true
        this.validityMap = null

        this.fetchData ({
          op: 'registry/config/loadTrustStoreValidity',
          params: {
            id: this.id
          },
          cb: data => {
            this.validityMap = data.validity
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
