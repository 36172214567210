<messages>["./Configuration"]</messages>

<!--
================================================================================
  Template
================================================================================
-->

<template>
  <base-layout :mw="$vuetify.breakpoint.xlOnly ? '3' : '2'">
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div
              v-t="'certstores.title'"
              class="text-h5"/>
            <div
              v-t="'certstores.subTitle'"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" xl="6">
            <v-row>
              <v-col cols="12">
                <v-progress-linear v-if="isLoading" indeterminate/>
                <certificate-store-dialog
                  v-else
                  v-model="showKeyStoreDialog"
                  type="key"
                  @success="onSuccess ('add', 'key', $event)">
                  <template #activator="{ on }">
                    <v-btn
                      v-t="'certstores.dialog.key.add'" v-on="on"/>
                  </template>
                </certificate-store-dialog>
              </v-col>
              <v-col cols="12">
                <cert-stores
                  v-if="trustStores && trustStores.length"
                  flat
                  type="key"
                  :stores="keyStores"
                  @update="onSuccess ('update', 'key', $event)"
                  @delete="onSuccess ('delete', 'key', $event)"
                  @deleteError="onDeleteError ('key', $event)"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="6">
            <v-row>
              <v-col cols="12">
                <certificate-store-dialog
                  v-if="!isLoading"
                  v-model="showTrustStoreDialog"
                  type="trust"
                  @success="onSuccess ('add', 'trust', $event)">
                  <template #activator="{ on }">
                    <v-btn
                      v-t="'certstores.dialog.trust.add'"
                      v-on="on"/>
                  </template>
                </certificate-store-dialog>
              </v-col>
              <v-col cols="12">
                <cert-stores
                  v-if="trustStores && trustStores.length"
                  flat
                  type="trust"
                  :stores="trustStores"
                  @update="onSuccess ('update', 'trust', $event)"
                  @delete="onSuccess ('delete', 'trust', $event)"
                  @deleteError="onDeleteError ('trust', $event)"/>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic
================================================================================
-->

<script>
  import CertStores from './components/CertificateStores'
  import CertificateStoreDialog from './components/CertificateStoreDialog'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import {mapActions, mapMutations} from 'vuex'

  export default {
    name: 'KeyTrustStores',

    components: {
      BaseLayout,
      CertStores,
      CertificateStoreDialog
    },

    data () {
      return {
        keyStores: [],
        trustStores: [],
        isLoading: false,
        showKeyStoreDialog: false,
        showTrustStoreDialog: false
      }
    },

    mounted () {
      this.loadStores ()
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),

      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        displayErrorMessage: 'notification/setError'
      }),

      ...mapActions ('request', ['fetchData']),

      /**
       * Load the key and trust stores from the backend.
       */
      loadStores () {
        this.isLoading = true
        this.keyStores = null
        this.trustStores = null

        return this.fetchData ({
          op: 'registry/config/list-stores',
          cb: data => {
            this.keyStores = data.keyStores
            this.trustStores = data.trustStores
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      /**
       * Display a success message when a certificate store has been added,
       * updated or deleted successfully.
       *
       * @param {String} op         the performed operation;
       *                            one of 'add', 'update', 'delete'
       * @param {String} type       the certificate store type;
                                    one of 'key', 'trust'
       * @param {String} name       the certificate store name
       */
      onSuccess (op, type, name) {
        this.addEvent ({
          type: 'success',
          message: this.$t ('certstores.dialog.' + type +
            '.' + op + 'Success', {store: name}),
          objects: [{name: this.$t ('certstores.title'), link: {name: 'config.certstores'}}]
        })

        this.loadStores ()

        this.displaySuccessMessage (this.$t ('certstores.dialog.' + type +
          '.' + op + 'Success', {store: name}))
      },

      /**
       * Display an error message when a certificate store could not be deleted.
       *
       * @param {String} type     the certificate store type;
       *                          one of 'key', 'trust'
       * @param {String} name     the certificate store name
       */
      onDeleteError (type, name) {
        this.displayErrorMessage (this.$t ('certstores.dialog.' + type +
          '.' + 'deleteError', {store: name}))
      }
    }
  }
</script>
