var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":_vm.flat}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{directives:[{name:"t",rawName:"v-t",value:(("certstores." + _vm.type + ".title")),expression:"`certstores.${type}.title`"}],staticClass:"text-h5"})]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","custom-sort":_vm.sortFunction,"headers":_vm.headers,"items":_vm.stores,"items-per-page":-1,"no-data-text":_vm.$t (("certstores." + _vm.type + ".empty"))},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{key:props.item.id},[_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(_vm.formatDate (props.item.validTo)))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(props.item.validity === 'valid')?_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v(" check_circle ")]):_vm._e(),(props.item.validity === 'expiring')?_c('v-icon',_vm._g({attrs:{"color":"orange"}},on),[_vm._v(" warning ")]):_vm._e(),(props.item.validity === 'expired')?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v(" error ")]):_vm._e(),(props.item.validity === 'not-yet-valid')?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v(" update ")]):_vm._e()]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(("stores.valdesc." + (props.item.validity))),expression:"`stores.valdesc.${props.item.validity}`"}]})]),(_vm.type === 'trust')?_c('validity-menu',{attrs:{"id":props.item.id,"name":props.item.name}}):_vm._e()],1),(_vm.showActions)?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(props.item.inUse)?_c('v-icon',_vm._g({},on),[_vm._v(" check ")]):_c('v-icon',_vm._g({attrs:{"color":"grey lighten-2"}},on),[_vm._v(" remove ")])]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(("stores.usagedesc." + (props.item.inUse))),expression:"`stores.usagedesc.${props.item.inUse}`"}]})])],1):_vm._e(),(_vm.showActions)?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":""},on:{"click":function($event){_vm.openDialog (props.item.name, 'update')}}},on),[_c('v-icon',[_vm._v("update")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('stores.update'),expression:"'stores.update'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":"","disabled":props.item.inUse},on:{"click":function($event){_vm.openDialog (props.item.name, 'delete')}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t (props.item.inUse ? 'stores.noDelete' : 'stores.delete'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":""},on:{"click":function($event){_vm.setDlDialog (props.item)}}},on),[_c('v-icon',[_vm._v("get_app")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('general.button.download'),expression:"'general.button.download'"}]})]),(_vm.type === 'trust')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":""},on:{"click":function($event){_vm.setUpDialog (props.item)}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('certstores.uploadToolTip'),expression:"'certstores.uploadToolTip'"}]})]):_vm._e(),(_vm.type === 'key')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":""},on:{"click":function($event){_vm.setUpdateDialog (props.item)}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('certstores.updateCert.update'),expression:"'certstores.updateCert.update'"}]})]):_vm._e(),(_vm.type === 'key')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"href":_vm.getCSRLink (props.item.id)}},on),[_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"icon":""}},[_c('v-icon',[_vm._v("gesture")])],1)],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('certstores.createCSR'),expression:"'certstores.createCSR'"}]})]):_vm._e()],1):_vm._e()])]}}])})],1),_c('certificate-store-dialog',{attrs:{"type":_vm.type,"name":_vm.name},on:{"success":function($event){_vm.$emit ('update', _vm.name)}},model:{value:(_vm.showUpdateDialog),callback:function ($$v) {_vm.showUpdateDialog=$$v},expression:"showUpdateDialog"}}),_c('confirmation-dialog',{attrs:{"headline":_vm.$t (("certstores.dialog." + _vm.type + ".delete")),"is-loading":_vm.isLoadingDelete},on:{"ok":function($event){_vm.onDelete (_vm.name)}},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('span',[_vm._v(_vm._s(_vm.deleteDialogText))])]),_c('store-download-dialog',{attrs:{"item":_vm.dlItem},on:{"ok":_vm.downloadCurrent,"cancel":_vm.cancel},model:{value:(_vm.dlDialog),callback:function ($$v) {_vm.dlDialog=$$v},expression:"dlDialog"}}),_c('add-cert-dialog',{attrs:{"item":_vm.uploadItem,"is-loading":_vm.loadingUpload},on:{"ok":_vm.uploadCurrent,"cancel":_vm.cancelUpload},model:{value:(_vm.upDialog),callback:function ($$v) {_vm.upDialog=$$v},expression:"upDialog"}}),_c('update-cert-dialog',{attrs:{"item":_vm.updateItem,"is-loading":_vm.loadingUpdate},on:{"ok":_vm.updateCert},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }