<messages>["../Configuration"]</messages>

<template>
  <confirmation-dialog
    v-model="dialog"
    hide-overflow
    :headline="$t (`certstores.updateCert.title`, {name: item ? item.name : ''})"
    :cancel-action="onCancel"
    :is-loading="isLoading"
    @ok="onOk">
    <form>
      <transition-group name="list-complete">
        <v-row
          v-for="(c, idx) in chain"
          :key="c.id" class="list-complete-item"
          :class="{highlight: idx%2}"
          dense
          align="center">
          <v-col cols="6" md="9">
            <file-upload
              v-model="c.name"
              :mime-types="mimeTypes"
              :label="$t ('certstores.cert')"
              :error-messages="validationErrors(idx)"
              @error="error($event, idx)"
              @load="fileLoaded($event, idx)"
              @blur="$v.chain.$each[idx].$touch()"/>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  class="ma-0 pa-0"
                  v-on="on"
                  @click="deleteItem(idx)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span v-t="'certstores.updateCert.delete'"/>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip v-if="idx!==0" bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  class="ma-0 pa-0"
                  v-on="on"
                  @click="shiftUp(idx)">
                  <v-icon>arrow_upward</v-icon>
                </v-btn>
              </template>
              <span v-t="'certstores.updateCert.shiftUp'"/>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip v-if="idx !== chain.length - 1" bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  class="ma-0 pa-0"
                  v-on="on"
                  @click="shiftDown(idx)">
                  <v-icon>arrow_downward</v-icon>
                </v-btn>
              </template>
              <span v-t="'certstores.updateCert.shiftDown'"/>
            </v-tooltip>
          </v-col>
        </v-row>
      </transition-group>
      <v-row>
        <v-col>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                class="ma-0 pa-0"
                v-on="on"
                @click="addCert">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span v-t="'certstores.updateCert.add'"/>
          </v-tooltip>
        </v-col>
      </v-row>
    </form>
  </confirmation-dialog>
</template>

<script>
  import {required} from 'vuelidate/lib/validators'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import FileUpload from '@/app/core/components/FileUpload/FileUpload'

  export default {

    components: {
      ConfirmationDialog,
      FileUpload
    },

    props: {
      value: {
        type: Boolean,
        required: true
      },

      item: {
        type: Object,
        default: undefined
      },

      isLoading: {
        type: Boolean,
        default: false
      }
    },

    validations: {
      chain: {
        $each: {
          cert: {
            required
          }
        },
        required
      }
    },

    data () {
      return {
        chain: [this.createNewCertItem ()],
        mimeTypes: [
          'application/x-x509-ca-cert',
          'application/x-x509-user-cert',
          'application/pkix-cert'
        ]
      }
    },

    computed: {
      dialog: {
        get () {
          return this.value
        },

        set (newVal) {
          this.$emit ('input', newVal)
        }
      }
    },

    methods: {
      onOk () {
        if (!this.$v.$invalid) {
          this.$emit ('ok', {
            chain: this.chain.map ((c) => ({data: c.cert})),
            errorCb: (data) => console.error ('error', data)
          })
        } else {
          this.$v.$touch ()
        }
      },

      onCancel () {
        this.dialog = false
        this.chain = [this.createNewCertItem ()]
        this.$v.$reset ()
      },

      validationErrors (idx) {
        const val = this.$v.chain.$each[idx]

        if (!val || !val.$dirty) {
          return []
        }

        if (val.$error) {
          return [
            this.$t ('certstores.updateCert.required')
          ]
        }

        return []
      },

      createNewCertItem () {
        return {
          name: '',
          cert: null,
          id: '_' + Math.random ().toString (36).substr (2, 9)
        }
      },

      addCert () {
        this.chain.push (this.createNewCertItem ())
      },

      deleteItem (idx) {
        this.chain.splice (idx, 1)
      },

      fileLoaded (e, idx) {
        this.fileError = false
        this.chain[idx].cert = e.data
      },

      error (e, idx) {

      },

      shiftUp (from) {
        const to = from - 1
        this.chain.splice (to, 0, this.chain.splice (from, 1)[0])
      },

      shiftDown (from) {
        const to = from + 1
        this.chain.splice (to, 0, this.chain.splice (from, 1)[0])
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_colors';

.list-complete-item {
  transition: all .5s;
}
.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
  width: 100%;
}

.highlight {
  background-color: map-get($grey, "lighten-3")
}
</style>
