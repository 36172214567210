var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('confirmation-dialog',{attrs:{"headline":_vm.$t (!!_vm.name ? ("certstores.dialog." + _vm.type + ".update") :
    ("certstores.dialog." + _vm.type + ".add")),"is-loading":_vm.isLoading,"error-msg":_vm.errorMessage},on:{"ok":_vm.onOk},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_vm._t("activator",null,{"on":on})]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('form',{attrs:{"novalidate":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t ('certstores.dialog.name'),"readonly":!!_vm.name,"spellcheck":"false","error-messages":_vm.requiredErrors (
              'storeData.name',
              'certstores.dialog.required.name')},model:{value:(_vm.storeData.name),callback:function ($$v) {_vm.$set(_vm.storeData, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"storeData.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('file-upload',{attrs:{"required":"","label":_vm.$t ('certstores.dialog.file'),"error-messages":_vm.validationErrors (
              'storeData.data',
              {
                required: 'certstores.dialog.required.data',
                validFile: 'certstores.dialog.invalid.data'
              })},on:{"load":_vm.onLoad,"error":_vm.onError},model:{value:(_vm.storeFileName),callback:function ($$v) {_vm.storeFileName=$$v},expression:"storeFileName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"required",attrs:{"label":_vm.$t ('certstores.dialog.type'),"items":_vm.availableTypes,"error-messages":_vm.requiredErrors (
              'storeData.type',
              'certstores.dialog.required.type')},model:{value:(_vm.storeData.type),callback:function ($$v) {_vm.$set(_vm.storeData, "type", $$v)},expression:"storeData.type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"id":"email","type":"text","name":"ffWorkaroundEmail"}}),_c('input',{staticStyle:{"display":"none"},attrs:{"id":"password","type":"password","name":"ffWorkaroundPassoword"}}),_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"new-password","label":_vm.$t ('certstores.dialog.storePw'),"spellcheck":"false","append-icon":_vm.isStorePwHidden ? 'visibility' : 'visibility_off',"type":_vm.isStorePwHidden ? 'password' : 'text',"error-messages":_vm.requiredErrors (
              'storeData.storePassword',
              'certstores.dialog.required.storePw')},on:{"click:append":function($event){_vm.isStorePwHidden = !_vm.isStorePwHidden}},model:{value:(_vm.storeData.storePassword),callback:function ($$v) {_vm.$set(_vm.storeData, "storePassword", $$v)},expression:"storeData.storePassword"}})],1),(_vm.type === 'key')?_c('v-col',{attrs:{"cols":"6"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"id":"email","type":"text","name":"ffWorkaroundEmail"}}),_c('input',{staticStyle:{"display":"none"},attrs:{"id":"password","type":"password","name":"ffWorkaroundPassoword"}}),_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t ('certstores.dialog.keyPw'),"spellcheck":"false","append-icon":_vm.isKeyPwHidden ? 'visibility' : 'visibility_off',"type":_vm.isKeyPwHidden ? 'password' : 'text',"error-messages":_vm.requiredErrors (
              'storeData.keyPassword',
              'certstores.dialog.required.keyPw')},on:{"click:append":function($event){_vm.isKeyPwHidden = !_vm.isKeyPwHidden}},model:{value:(_vm.storeData.keyPassword),callback:function ($$v) {_vm.$set(_vm.storeData, "keyPassword", $$v)},expression:"storeData.keyPassword"}})],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }