var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('confirmation-dialog',{attrs:{"hide-overflow":"","headline":_vm.$t ("certstores.updateCert.title", {name: _vm.item ? _vm.item.name : ''}),"cancel-action":_vm.onCancel,"is-loading":_vm.isLoading},on:{"ok":_vm.onOk},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('form',[_c('transition-group',{attrs:{"name":"list-complete"}},_vm._l((_vm.chain),function(c,idx){return _c('v-row',{key:c.id,staticClass:"list-complete-item",class:{highlight: idx%2},attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"6","md":"9"}},[_c('file-upload',{attrs:{"mime-types":_vm.mimeTypes,"label":_vm.$t ('certstores.cert'),"error-messages":_vm.validationErrors(idx)},on:{"error":function($event){return _vm.error($event, idx)},"load":function($event){return _vm.fileLoaded($event, idx)},"blur":function($event){return _vm.$v.chain.$each[idx].$touch()}},model:{value:(c.name),callback:function ($$v) {_vm.$set(c, "name", $$v)},expression:"c.name"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(idx)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('certstores.updateCert.delete'),expression:"'certstores.updateCert.delete'"}]})])],1),_c('v-col',[(idx!==0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":""},on:{"click":function($event){return _vm.shiftUp(idx)}}},on),[_c('v-icon',[_vm._v("arrow_upward")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('certstores.updateCert.shiftUp'),expression:"'certstores.updateCert.shiftUp'"}]})]):_vm._e()],1),_c('v-col',[(idx !== _vm.chain.length - 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":""},on:{"click":function($event){return _vm.shiftDown(idx)}}},on),[_c('v-icon',[_vm._v("arrow_downward")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('certstores.updateCert.shiftDown'),expression:"'certstores.updateCert.shiftDown'"}]})]):_vm._e()],1)],1)}),1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":""},on:{"click":_vm.addCert}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('certstores.updateCert.add'),expression:"'certstores.updateCert.add'"}]})])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }