<messages>["./FileUpload"]</messages>

<!--
================================================================================
  Template
================================================================================
-->

<template>
  <div>
    <v-text-field
      v-model="value"
      readonly
      :label="fieldLabel"
      :class="['file-name-field', {required}]"
      :append-icon="icon"
      :loading="loading"
      :error-messages="errors"
      @blur="$emit('blur')"
      @click:append="onSelectFile"
      @click="onSelectFile">
      <template #progress>
        <v-progress-linear :value="loadProgress"/>
      </template>
    </v-text-field>
    <input
      ref="fileInput"
      type="file"
      style="display: none"
      @change="onSelectFileInternal">
  </div>
</template>

<!--
================================================================================
  Logic
================================================================================
-->

<script>
  const ICON_WARN = 'warning'
  const ICON_SUCCESS = 'check'
  const ICON_FILE = 'attach_file'

  const MAX_FILE_BYTES = 10000000
  const MAX_FILE_SIZE = '10 MB'

  export default {
    name: 'FileUpload',

    props: {
      value: {
        // the file name to be displayed in the text field
        type: String,
        default: undefined
      },
      mimeTypes: {
        // the allowed MIME types (all types allowed if not set)
        type: Array,
        default: undefined
      },
      label: {
        // the text field label
        type: String,
        default: undefined
      },
      required: {
        // whether selecting a file is mandatory in the enclosing form
        type: Boolean,
        default: false
      },
      errorMessages: {
        // the error messages to display
        type: Array,
        default: undefined
      },
      text: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        fileReader: null,
        loading: false,
        loadProgress: 0,
        loadErrors: [],
        icon: ICON_FILE
      }
    },

    computed: {
      fieldLabel () {
        return this.label || this.$t ('select')
      },

      errors () {
        // prefer component-internal errors over errors passed via prop
        return this.loadErrors.length > 0
          ? this.loadErrors
          : this.errorMessages
      }

    },

    watch: {
      loading () {
        this.loadProgress = 0
      },

      value (newValue) {
        if (!newValue || newValue.length === 0) {
          this.icon = ICON_FILE
          this.loadErrors = []
        }
      }
    },

    created () {
      this.initFileReader ()
    },

    methods: {
      /**
       * Initiate file loading after click on text field.
       */
      onSelectFile () {
        this.$refs.fileInput.value = ''
        this.$refs.fileInput.click ()
      },

      /**
       * Load the file specified by the given event.
       *
       * @param {Object} e      the event object specifying the file to be
       *                        loaded
       */
      onSelectFileInternal (e) {
        console.warn (e)
        const file = e.target.files[0]

        if (file) {
          this.$emit ('input', file.name)

          if (this.mimeTypes && !this.mimeTypes.includes (file.type)) {
            this.icon = ICON_WARN
            this.loadErrors = [this.$t ('typeError')]
            this.$emit ('error', 'unexpected MIME type')
          } else if (file.size > MAX_FILE_BYTES) {
            this.icon = ICON_WARN
            this.loadErrors = [this.$t ('sizeError', {size: MAX_FILE_SIZE})]
            this.$emit ('error', 'maximum file size exceeded')
          } else {
            this.text
              ? this.fileReader.readAsText (file)
              : this.fileReader.readAsDataURL (file)
          }
        }
      },

      /**
       * Initialize the file reader used by this component.
       */
      initFileReader () {
        this.fileReader = new window.FileReader ()

        // start loading file
        this.fileReader.addEventListener ('loadstart', () => {
          this.icon = ICON_FILE
          this.loadErrors = []
          this.loading = true
        })

        // file loading in progress
        this.fileReader.addEventListener ('progress', e => {
          this.loadProgress = 100 * (e.loaded / e.total)
        })

        // file successfully loaded
        this.fileReader.addEventListener ('load', () => {
          if (!this.text) {
            this.icon = ICON_SUCCESS

            const dataUrlPrefix = 'data:'
            const base64Indicator = ';base64,'
            const index = this.fileReader.result.indexOf (base64Indicator)

            const mimeType = this.fileReader.result.substring (
              dataUrlPrefix.length, index)

            const base64Content = this.fileReader.result.substring (
              index + base64Indicator.length)

            this.$emit ('load', {type: mimeType, data: base64Content})
          } else {
            this.$emit ('load', this.fileReader.result)
          }
        })

        // error while loading file
        this.fileReader.addEventListener ('error', () => {
          this.icon = ICON_WARN
          this.loadErrors = [this.$t ('loadError')]
          this.$emit ('error', this.fileReader.error)
        })

        // file loading finished (with or without errors)
        this.fileReader.addEventListener ('loadend', () => {
          this.loading = false
        })
      }
    }
  }
</script>

<!--
================================================================================
  Styling
================================================================================
-->

<style lang="scss" scoped>
.file-name-field {
  input {
    cursor: pointer;
  }
}
</style>
