<messages>["../Configuration"]</messages>

<template>
  <confirmation-dialog
    v-model="dialog"
    :headline="$t (`certstores.uploadCert`, {name: item ? item.name : 'n/a'})"
    :is-loading="isLoading"
    @cancel="onCancel"
    @ok="onOk">
    <form>
      {{ $t('certstores.uploadCertSubtitle') }}
      <v-text-field
        v-model.trim="alias"
        :label="$t('certstores.alias')"
        @blur="$v.alias.$touch()"/>
      <file-upload
        v-model="certName"
        :mime-types="mimeTypes"
        :error-messages="validationErrors (
          'certName', {
            required: 'general.required'
          }
        )"
        :label="$t ('certstores.cert')"
        @error="error"
        @load="fileLoaded"/>
    </form>
  </confirmation-dialog>
</template>

<script>
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import FileUpload from '@/app/core/components/FileUpload/FileUpload'

  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import {required} from 'vuelidate/lib/validators'

  export default {
    components: {
      ConfirmationDialog,
      FileUpload
    },

    mixins: [validationMixins],

    validations: {
      certName: {required}
    },

    props: {
      value: {
        type: Boolean,
        required: true
      },
      item: {
        type: Object,
        default: undefined
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        alias: '',
        cert: null,
        certName: '',
        fileError: true,
        mimeTypes: [
          'application/x-x509-ca-cert',
          'application/x-x509-user-cert',
          'application/pkix-cert'
        ]
      }
    },

    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      }
    },

    watch: {
      value (newVal) {
        if (!newVal) {
          this.alias = ''
          this.cert = null
          this.certName = ''

          this.$v.$reset ()
        }
      }
    },

    methods: {
      onOk () {
        if (!this.$v.$invalid && !this.fileError) {
          this.$emit ('ok', {alias: this.alias, cert: this.cert})
        } else {
          this.$v.$touch ()
        }
      },
      onCancel () {
        this.$emit ('cancel')
      },
      fileLoaded (e) {
        this.fileError = false
        this.cert = e.data
      },
      error () {
        this.fileError = true
        this.certName = ''
        this.cert = null
      }
    }
  }
</script>
